<template>
  <!-- prettier-ignore -->
  <index-content>
    <search-condition searchable :loading="searching" @do-search="search">
      <ns-company v-model="condition.companyId" />
      <ns-group v-model="condition.groupId" :company-id="condition.companyId" all-option />
      <n-field-enum v-model="condition.method" enum-name="LoginMethod" label="loginMethod" all-option />
      <n-field-text v-model="condition.loginId" label="loginId" />
      <n-field-id v-model="condition.id" label="customerId" />
      <n-field-text v-model="condition.email" label="email" />
      <n-field-text v-model="condition.lastName" label="lastName" />
      <n-field-text v-model="condition.firstName" label="firstName" />
    </search-condition>
    <n-table :total="total" :page-no="pageNo" :page-size="pageSize" :searched="searched" :searching="searching" :has-result="hasResult" @on-page-no="onPageNo" @on-page-size="onPageSize">
      <table class="table table-bordered table-hover table-striped text-center n-table">
        <thead>
          <tr>
            <th class="col-weight-9">{{ $t('label.company') }}</th>
            <th class="col-weight-9">{{ $t('label.customerId') }}</th>
            <th class="col-weight-9">{{ $t('label.country') }}</th>
            <th class="col-weight-24">{{ $t('label.loginId') }}</th>
            <th class="col-weight-9">{{ $t('label.loginMethod') }}</th>
            <th class="col-weight-9">{{ $t('label.firstName') }}</th>
            <th class="col-weight-9">{{ $t('label.lastName') }}</th>
            <th class="col-weight-9">{{ $t('label.status') }}</th>
            <th class="n-table-timestamp">{{ $t('label.lastUpdate') }}</th>
            <th v-table-action class="action-edit">{{ $t('label.action') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in records" :key="row.id">
            <td v-selection:[`company.company`]="row.companyId"></td>
            <td>{{ row.id }}</td>
            <td v-selection:[`market.country`]="row.countryId"></td>
            <td class="n-ellipsis" :title="row.loginId">{{ row.loginId | hyphen }}</td>
            <td v-enums:LoginMethod="row.loginMethod"></td>
            <td class="n-ellipsis" :title="row.firstName">{{ row.firstName | hyphen }}</td>
            <td class="n-ellipsis" :title="row.lastName">{{ row.lastName | hyphen }}</td>
            <td v-enums:CustomerProfileStatus="row.status"></td>
            <td>{{ row.updateDatetime | datetime }}</td>
            <td class="n-button-box">
              <nb-modal @on-click="openModal(row)" />
            </td>
          </tr>
        </tbody>
      </table>
    </n-table>
  </index-content>
</template>

<script>
import CustomerView from './view';
import PaginateMixin from '@/mixins/paginate-mixin';
import ModalProfile from './ModalProfile';
import { fetch } from '@/api/customer/customer-profile';
import {Parser, setProfileExtra} from "@/helpers/operators";

export default CustomerView.extend({
  name: 'CustomerProfile',
  mixins: [PaginateMixin],
  data() {
    return {
      condition: {
        companyId: null,
        loginId: null,
        id: null,
        email: null,
        firstName: null,
        lastName: null,
        method: 0,
        groupId: 0,
      },
    };
  },
  methods: {
    doSearch(params) {
      let p = { ...params, ...this.condition };
      return fetch(p);
    },
    parse(rows) {
      this.records = Parser( rows.records, [setProfileExtra]);
    },
    openModal(model = {}) {
      this.createModal(ModalProfile, { model, on: this });
    },
  },
});
</script>
